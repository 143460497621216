import axios from 'axios'

import store from '../store'
import router from '../router'

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 20000
  // withCredentials: true
})

/**
 * Enviamos el Token y el idioma público en cada petición axios
 */
service.interceptors.request.use(
  config => {
    if (store.state.auth.token) {
      config.headers['Authorization'] = 'Bearer ' + store.state.auth.token
    }

    if (store.state.language.idiom) {
      config.headers['X-Language'] = store.state.language.idiom.code
    }

    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response

    // Si no tiene un Token o está vencido, lo enviamos al formulario de Login
    if (status === 401) {
      store.commit('auth/logout')
      if (router.history.current.name !== 'Login')
        router.push({ name: 'Login' })
    }
    return Promise.reject(error)
  }
)

export default service
