<template>
  <v-overlay :value="loading" z-index="9999">
    <v-progress-circular indeterminate :size="size" />
  </v-overlay>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false
    },
    size: {
      type: Number,
      default: 64
    }
  }
}
</script>

<style scoped></style>
