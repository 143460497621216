import DefaultLayout from '@/layouts/DefaultLayout'
// import general from './general'

export default [
  // Maintenance
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */
        '../views/Maintenance'
      )
  },
  // Login
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/auth/Login')
  },
  {
    path: '/preguntas-de-seguridad',
    name: 'SecureQuestions',
    meta: { guest: true },
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/auth/SecureQuestions')
  },
  {
    path: '/recuperar-contrasena',
    name: 'ForgotPassword',
    meta: { guest: true },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '../views/auth/ForgotPassword'
      )
  },
  {
    path: '/restablecer-contrasena',
    name: 'ResetPassword',
    meta: { guest: true },
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */
        '../views/auth/ResetPassword'
      )
  },
  {
    path: '/recuperar-usuario',
    name: 'ForgotUser',
    meta: { guest: true },
    component: () =>
      import(
        /* webpackChunkName: "forgot-username" */ '../views/auth/ForgotUsername'
      )
  },
  {
    path: '/configurar-usuario',
    name: 'ResetPassword',
    meta: { guest: true },
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */
        '../views/auth/ConfigPassword'
      )
  },
  {
    path: '/404',
    name: '404',
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */
        '../views/404'
      )
  },
  // Dashboard
  {
    path: '*',
    redirect: { name: '404' }
  },
  // Dashboard

  {
    path: '/',
    component: DefaultLayout,
    meta: { auth: true, title: 'Inicio' },
    children: [
      {
        path: '',
        name: 'Home',
        component: () =>
          import(/* webpackChunkName: "home" */ '../views/home/Index')
      },
      {
        path: '/seleccion-cuenta',
        name: 'AccountSelect',
        component: () =>
          import(/* webpackChunkName: "accounts" */ '../views/accounts/Index')
      },
      {
        name: 'Transfers',
        path: '/transferencias',
        meta: {
          title: 'Transferencias',
          section: 'permanent-instructions',
          permission: 'menu-transfers'
        },
        component: () =>
          import(
            /* webpackChunkName: "transfers" */ '../views/transfers/Index'
          )
      },
      {
        name: 'RegisterAccounts',
        path: '/registro-de-cuentas',
        meta: {
          title: 'transfer_account_register',
          section: 'account-registration',
          permission: 'menu-account-registration'
        },
        component: () =>
          import(
            /* webpackChunkName: "transfers" */ '../views/transfers/Edit'
          )
      },
      {
        name: 'Retire',
        path: '/retiro-de-fondos',
        meta: { title: 'Retiro de Fondos', section: 'funds-withdrawal-request' },
        component: () =>
          import(
            /* webpackChunkName: "retires" */ '../views/retires/Index'
          )
      },
      {
        name: 'RetireCreate',
        path: '/retiro-de-fondos/create',
        meta: { title: 'Creacion de Solicitud de Retiro', section: 'funds-withdrawal-request' },
        component: () =>
          import(
            /* webpackChunkName: "retires" */ '../views/retires/Edit'
          )
      },
      {
        name: 'Deposit',
        path: '/deposit-de-fondos',
        meta: { title: 'Depósito de Fondos', section: 'notification-funds-deposits' },
        component: () =>
          import(
            /* webpackChunkName: "deposit" */ '../views/deposit/Index'
          )
      },
      {
        name: 'DepositCreate',
        path: '/deposit-de-fondos/create',
        meta: {
          title: 'Creacion de Solicitud de Depósito',
          section: 'notification-funds-deposits'
        },
        component: () =>
          import(
            /* webpackChunkName: "deposit" */ '../views/deposit/Edit'
          )
      },
      // {
      //   path: '/barTest',
      //   name: 'BarTest',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "forgot-password" */ '../views/test/BarTest'
      //     )
      // }
      {
        path: '/instrucciones-permanentes',
        meta: { title: 'Instrucciones Permanentes' },
        component: { template: '<router-view />' },
        children: [{
                path: '',
                name: 'PermanentInstructions',
                meta: { title: 'Instrucciones Permanentes', section: 'permanent-instructions' },
                component: () =>
                    import (
                        /* webpackChunkName: "PermanentInstructions" */
                        '../views/permanentInstructions/Index'
                    ),
            },
            {
                path: 'create',
                name: 'PermanentInstructionsCreate',
                meta: { title: 'Create Permanent Instruction' },
                component: () =>
                    import (
                        /* webpackChunkName: "PermanentInstructions" */
                        '../views/permanentInstructions/Edit'
                    )
            },
        ]
      },
      {
        path: '/twofactor-authenticator',
        name: 'Delete',
        component: () =>
          import(
            /* webpackChunkName: "2fa" */
            '../views/2fa/Twofactor.vue'
          )
      },
    ]
  }
]
