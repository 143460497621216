/**
 * Set the settings of the application
 * @author Edwin Verdú
 * @param {Object} settings - Current settings
 *
 */
  export async function setSettings({ commit }, settings) {
    commit('SET_SETTINGS', settings)
  }

  /**
 * Set the maintenance status
 * @author Edwin Verdú
 * @param {Object} maintenance - Current maintenance status
 *
 */
   export async function setMaintenance({ commit }, maintenance) {
    commit('SET_MAINTENANCE', maintenance)
  }

  /**
 * Set the white branding status
 * @author Edwin Verdú
 * @param {Object} whiteBranding - Current white branding status
 *
 */
   export async function setWhiteBranding({ commit }, whiteBranding) {
    commit('SET_WHITE_BRANDING', whiteBranding)
  }
