/**
 * Set the current account
 * @author Cauly Pérez
 * @param {Object} account - Current account
 *
 */
export async function setAccount({ commit }, account) {
  commit('SET_ACCOUNT', account)
}

/**
 * Set the user accounts
 * @author Cauly Pérez
 * @param {Array} accounts - User accounts
 *
 */
export function setAccounts({ commit }, accounts) {
  commit('SET_ACCOUNTS', accounts)
}

/**
 * Set the current account Data
 * @author Cauly Pérez
 * @param {Object} accountData - Current accountData
 *
 */
export async function setAccountData({ commit }, accountData) {
  commit('SET_ACCOUNT_DATA', accountData)
}

/**
 * Set the user accounts
 * @author Edwin Verdú
 * @param {Array} authorityAccounts - User authority accounts
 *
 */
 export function setAuthorityAccounts({ commit }, authorityAccounts) {
  commit('SET_AUTHORITY_ACCOUNTS', authorityAccounts)
}

/**
 * Set the user accounts
 * @author Cauly Pérez
 * @param {Object} products - Products
 *
 */
export function setProducts({ commit }, products) {
  commit('SET_PRODUCTS', products)
}
