import BaseService from './base'
import axios from '@/plugins/axios'

export default class BrokerService extends BaseService {
  static resource() {
    return 'api/v1/broker'
  }

  static async getBrokerInfo(code, payload = {}) {
    const { data } = await axios.get(
      `${this.baseURL()}/api/v1/broker/info/${code}`,
      payload
    )
    return data
  }
}
