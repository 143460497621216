export function SET_LANGUAGE(state, language) {
  state.language = language
}

export function SET_LANGUAGES(state, languages) {
  state.languages = languages
}

export function SET_IDIOM(state, idiom) {
  state.idiom = idiom
}
