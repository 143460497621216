import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
    required,
    email,
    length,
    max,
    min,
    min_value,
    max_value,
    size,
    confirmed,
    ext,
    alpha_num,
    numeric,
    digits
} from 'vee-validate/dist/rules'
import { localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'
// import { localize } from '@vee-validate/i18n'

// Add rules
extend('required', required)
extend('email', email)
extend('length', length)
extend('min_value', min_value)
extend('min', min)
extend('max', max)
extend('max_value', max_value)
extend('ext', ext)
extend('size', size)
extend('numeric', numeric)
extend('confirmed', confirmed)
extend('alpha_num', alpha_num)
extend('digits', digits)

localize('es', es)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)