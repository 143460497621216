<template>
  <v-app class="">
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import BrokerService from '@/services/broker'
import PublicSettingService from '@/services/publicSetting'

export default {
  name: 'App',
  data() {
    return {
      broker: {},
      whiteBrandingSetting: null
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('setting', ['whiteBranding']),
  },
  async created() {
    await this.getPublicSettings()
    this.handleWhiteBranding()
    const searchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(searchParams.entries())
    const broker = params.broker
    
    if (broker && this.whiteBranding) {
      await this.getBrokerInfo(broker)
      if (this.broker.id) {
        this.$vuetify.theme.themes.light.primary = this.broker.color
      }
    }
  },
  methods: {
    ...mapActions('setting', ['setWhiteBranding']),
    async getBrokerInfo(code) {
      this.isLoading = true
      try {
        const { data} = await BrokerService.getBrokerInfo(code)
        this.broker = data
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false
      }
    },

    async getPublicSettings() {
      const { data } = await PublicSettingService.fetch({})
      this.whiteBrandingSetting = data.find(item => item.key == 'white_branding')?.value
      this.setWhiteBranding(this.whiteBrandingSetting)
    },

    handleWhiteBranding() {
      if (this.user?.broker && this.whiteBranding) {
        this.$vuetify.theme.themes.light.primary = this.user.broker.color
      }
    }
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');
.theme--light.v-application {
  background: #fbfbfb;
  font-family: 'Lato', sans-serif;
}

.v-tabs-bar {
  border-bottom: 1px solid #e0e0e0;
}

.v-application--wrap {
  min-height: 100% !important;
}

.v-main {
  min-height: 100vh;
}

.menu-content {
  max-height: 100% !important;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  /* background-color: #555b00 !important; */
  /* color: #e8e6e3 !important; */
  color: #e8e6e3;
}

::-webkit-scrollbar {
  width: 7.5px;
  height: 7.5px;
}
::-webkit-scrollbar {
  background-color: white;
  color: #aba499;
}
::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #d8d8d8;
}
::-webkit-scrollbar-thumb:active {
  background-color: #d8d8d8;
}
::-webkit-scrollbar-corner {
  background-color: #d8d8d8;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}
/* ::selection {
  background-color: #004daa !important;
  color: #e8e6e3 !important;
}
::-moz-selection {
  background-color: #004daa !important;
  color: #e8e6e3 !important;
} */

body {
  scrollbar-color: #01579b white;
}

html {
  scrollbar-color: #01579b white;
}

.v-menu__content.theme--light.menuable__content__active.v-autocomplete__content {
  border-radius: 10px;
}
</style>
