import masker from 'vue-the-mask/src/masker'
import { tokens } from 'vue-the-mask'

export const MASK_PHONE = '(####)###-##-##'
export const MASK_DATE = '##/##/####'
export const MASK_PERSON_NATURAL = '##.###.###'
export const MASK_PERSON_JURIDIC = '########-#'

export function unmask(maskedValue, mask) {
  return masker(maskedValue, mask, false, tokens)
}

export function mask(value, mask, tokens = tokens) {
  return masker(value, mask, true, tokens)
}
