<template>
  <v-footer
    app
    color="primary"
    class="d-print-none footer"
    elevation="5"
    :height="heightFooter"
    padless
  >
    <v-container
      class="d-flex flex-column flex-md-row justify-space-between align-center container-footer"
    >
      <div class="Copyright">
        <small>
          © {{ currentYear }} AV Securities Inc. All Rights Reserved.
        </small>
      </div>
      <v-spacer :v-if="handleSpacer" />
      <div class="d-flex align-center ">
        <small class="">Powered by</small>
        <a href="https://www.logoscorp.com/" target="_blank" class="ml-1 mt-1"
          ><img
            class="logo"
            :src="logoscorp"
            :height="heightLogo"
            alt="Logoscorp"
        /></a>
      </div>
    </v-container>
  </v-footer>
</template>
<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      logoscorp: require('@/assets/logo_footer.svg'),
      currentYear: new Date().getFullYear()
    }
  },
  computed: {
    heightFooter() {
      return this.$vuetify.breakpoint.smAndDown ? 40 : 40
    },
    heightLogo() {
      return this.$vuetify.breakpoint.smAndDown ? 17 : 17
    },
    handleSpacer() {
      return this.$vuetify.breakpoint.smAndDown ? false : true
    }
  }
}
</script>
<style scoped>
.footer div,
.footer div > a {
  color: white;
  line-height: 14px;
  text-decoration: none;
}

.container-footer {
  padding: 0;
  font-size: 1rem;
}

@media only screen and (max-width: 450px) {
  .container-footer {
    padding: 0;
    font-size: 0.75rem;
  }
  .logo {
    margin-top: 0px;
  }
  .footer div > a {
    color: white;
    line-height: 6px;
    text-decoration: none;
  }
}
</style>
