import store from '@/store'
import Vue from 'vue'

/**
 * Directive to enabled / disabled element
 */
Vue.directive('can', {
  inserted(el, binding) {
    let enabled = store.state.auth.user.roles.some(role => {
      return role.permissions.some(item => item === binding.value)
    })

    const classList = el.classList
    el.disabled = !enabled
    if (enabled) {
      el.classList.remove('v-btn--disabled')
      el.classList = classList
    } else {
      el.classList.add('v-btn--disabled')
      el.classList.remove('v-btn--is-elevated')
    }
  }
})
