import i18n from '@/plugins/vue-i18n'
/**
 * Set the current language
 * @author Cauly Pérez
 * @param {Object} language - Current language
 *
 */
export async function setLanguage({ commit }, language) {
  commit('SET_LANGUAGE', language)
  i18n.locale = language.code
  i18n.setLocaleMessage(language.code, language.translations)
}

/**
 * Set languages
 * @author Cauly Pérez
 * @param {Array} languages
 *
 */
export function setLanguages({ commit }, languages) {
  commit('SET_LANGUAGES', languages)
}

export async function setIdiom({ commit }, idiom) {
  commit('SET_IDIOM', idiom)
  i18n.locale = idiom.code
  i18n.setLocaleMessage(idiom.code, idiom.translations)
}
