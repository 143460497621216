<template>
  <!-- <sub-menu
    icon="mdi-web"
    :name="idiom.name"
    :items="items"
    @click="handleLocale"
    item-class="mx-auto"
  /> -->
  <v-list-group>
    <template #activator>
      <v-list-item-title class="font-weight-bold item-list">
        <v-icon color="#686f8d">
          mdi-web
        </v-icon>
        <span class="ml-2">
          {{ idiom.name }}
        </span>
      </v-list-item-title>
    </template>
    <v-list-item v-for="i in items" :key="i.index" ripple>
      <v-list-item-title 
        @click="handleLocale(i)"
        class="list_color text--darken-2"
        style="padding-left: 58.5px;"
      >
        {{ i.title }}
      </v-list-item-title>
    </v-list-item>
  </v-list-group>
</template>

<script>
// import SubMenu from '@/components/commons/SubMenu'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'LanguageMenuDrawer',
  // components: { SubMenu },
  computed: {
    ...mapState('language', ['languages', 'idiom']),
    items() {
      return this.languages
        .filter(item => item.code !== this.idiom.code)
        .map(item => {
          return {
            ...item,
            title: item.name
          }
        })
    }
  },
  methods: {
    ...mapActions('language', ['setIdiom']),
    ...mapActions(['setLoading']),
    handleLocale(item) {
      this.setLoading(true)
      try {
        /*
        this.$i18n.locale = item.code
        this.$i18n.setLocaleMessage(item.code, item.translations)
        */
        this.setIdiom(item)
      } catch (error) {
        console.log(error)
      } finally {
        this.setLoading(false)
      }
    }
  },
  created() {
    this.setIdiom(this.idiom)
  }
}
</script>

<style>
.v-list-item__title {
  color: #686f8d;
}

.margin-logo {
  margin-left: 35px;
  padding: 0;
}

.margin-list {
  padding: 0;
  /* padding-left: 32px; */
}

.margin-list a {
  padding-left: 47px;
  font-weight: bold;
}

.item-list {
  /* padding: 0; */
  padding-left: 32px !important;
}

.navbutton {
  text-transform: none !important;
  font-weight: bold;
}

.bottom_list {
  /* padding-left: 32px !important; */
  margin-left: -3.5px;
}
.bottom_list:last-child {
  /* padding-left: 32px !important; */
  margin-left: 1.5px;
}

.list_color {
  color: #686f8d !important;
  font-weight: bold;
}

/* .theme--light.v-icon {
  color: #686f8d !important;
} */
.init {
  margin-left: 2px;
}
</style>
