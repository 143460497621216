import colors from 'vuetify/lib/util/colors'
import { localize } from 'vee-validate'

export function debounce(func, wait = 400) {
  let timerId
  return (...args) => {
    clearTimeout(timerId)
    timerId = setTimeout(() => {
      func.apply(this, args)
    }, wait)
  }
}

function getVuetifyHexColor(color) {
  return Object.keys(color)
    .filter(item => item !== 'lighten5')
    .map(item => color[item])
}

export function getChartColors() {
  const blueColors = getVuetifyHexColor(colors.indigo)
  const lightBlueColors = getVuetifyHexColor(colors.blue)
  const cyanColors = getVuetifyHexColor(colors.cyan)
  const tealColors = getVuetifyHexColor(colors.teal)
  const indigoColors = getVuetifyHexColor(colors.indigo)
  const deepPurpleColors = getVuetifyHexColor(colors.deepPurple)

  return blueColors
    .concat(lightBlueColors)
    .concat(cyanColors)
    .concat(tealColors)
    .concat(indigoColors)
    .concat(deepPurpleColors)
}

export function setVeeLocale(code) {
  return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
    localize(code, locale)
  })
}

export function hasPermission(user, permission) {
  return user?.roles.some(role => {
    return role.permissions.some(e => e === permission)
  })
}

export function isObjectEmpty(object) {
  return Object.keys(object).length === 0
}

export function getObjectURLFromBase64(base64, contentType) {
  const byteCharacters = window.atob(base64)
  let byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const file = new Blob([byteArray], { type: contentType })
  return URL.createObjectURL(file)
}
