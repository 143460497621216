import Vue from 'vue'
import vuetify from './vuetify'
import store from '../store'
import axios from '../plugins/axios'
import i18n from './vue-i18n'

import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

const context = {
  store,
  vuetify,
  axios,
  i18n
}

Vue.use(VuetifyDialog, {
  context,
  notification: {
    position: 'bottom-right',
    icon: false
  },
  warning: {
    title: 'Confirmación',
    icon: 'mdi-delete',
    color: 'primary',
    actions: {
      false: 'No',
      true: {
        text: 'Si',
        color: 'primary'
      }
    }
  }
})
