/**
 * Set the current request
 * @author Edwin Verdú
 * @param {Object} request - Current request
 *
 */
  export async function setRequest({ commit }, request) {
    commit('SET_REQUEST', request)
  }

  /**
 * Set the current client request
 * @author Edwin Verdú
 * @param {Object} clientRequest - Current client request
 *
 */
  export async function setClientRequest({ commit }, clientRequest) {
    commit('SET_CLIENT_REQUEST', clientRequest)
  }

/**
 * Set the boolean of transfer redirect
 * @author Edwin Verdú
 * @param {Boolean} transferRedirect - Transfer redirect
 *
 */
  export async function setTransferRedirect({ commit }, transferRedirect) {
    commit('SET_TRANSFER_REDIRECT', transferRedirect)
  }

/**
 * Set the permanent instruction
 * @author Edwin Verdú
 * @param {Object} permanentInstruction - current permanent instruction
 *
 */
  export async function setPermanentInstruction({ commit }, permanentInstruction) {
    commit('SET_PERMANENT_INSTRUCTION', permanentInstruction)
  }
