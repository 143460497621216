<template>
  <div ref="tradingview" :id="container" class="tradingview-widget-container">
    <div class="tradingview-widget-container__widget"></div>
  </div>
</template>

<script>
const SCRIPT_ID = 'tradingview-widget-script'
const CONTAINER_ID = 'tradingview-widget-container'
export default {
  data() {
    return {
      container: CONTAINER_ID
    }
  },
  props: {
    options: {
      type: Object,
      default: () => ({
        symbols: [
          {
            proName: 'FOREXCOM:SPXUSD',
            title: 'S&P 500'
          },
          {
            proName: 'FOREXCOM:NSXUSD',
            title: 'US 100'
          },
          {
            proName: 'FX_IDC:EURUSD',
            title: 'EUR/USD'
          },
          {
            proName: 'BITSTAMP:BTCUSD',
            title: 'BTC/USD'
          },
          {
            proName: 'BITSTAMP:ETHUSD',
            title: 'ETH/USD'
          },
          {
            proName: 'SKILLING:NASDAQ',
            title: 'NASDAQ'
          },
          {
            proName: 'FXOPEN:GDAXIM',
            title: 'DAX'
          },
          {
            proName: 'HSI:HSI',
            title: 'HANG SENG INDEX'
          },
          {
            proName: 'FOREXCOM:DJI',
            title: 'DOW JONES'
          },
          {
            proName: 'CURRENCYCOM:UK100',
            title: 'FTSE 100'
          }
        ],
        showSymbolLogo: false,
        colorTheme: 'light',
        isTransparent: false,
        displayMode: 'adaptive',
        locale: 'es'
      })
    }
  },
  methods: {
    canUseDOM() {
      return (
        typeof window !== 'undefined' &&
        window.document &&
        window.document.createElement
      )
    },
    getScriptElement() {
      return document.getElementById(SCRIPT_ID)
    },
    updateOnloadListener(onload) {
      const script = this.getScriptElement()
      const oldOnload = script.onload
      return (script.onload = () => {
        oldOnload()
        onload()
      })
    },
    scriptExists() {
      return this.getScriptElement() !== null
    },
    appendScript(onload) {
      if (!this.canUseDOM()) {
        onload()
        return
      }

      if (this.scriptExists()) {
        if (typeof TradingView === 'undefined') {
          this.updateOnloadListener(onload)
          return
        }
        onload()
        return
      }
      const script = document.createElement('script')
      script.id = SCRIPT_ID
      script.type = 'text/javascript'
      script.async = true
      script.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js'
      script.onload = onload
      script.textContent = JSON.stringify(this.options)

      this.$refs.tradingview.appendChild(script)
    },
    initWidget() {
      // Code on init widget
    }
  },
  mounted() {
    this.appendScript(this.initWidget)
  }
}
</script>
<style>
.tradingview-widget-container {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0.7;
}

iframe#tradingview-widget-script {
  height: 40px !important;
}
</style>
