import Vue from 'vue'
import { mask } from '../utils/mask'

Vue.filter('date', (value, format = 'DD MMMM YYYY') => {
  if (!value) return ''
  return Vue.prototype.$date(value).format(format)
})

Vue.filter('padStart', (value, targetLength = 6, padString = '0') => {
  if (!value) return ''
  const val = value.toString()
  return val.padStart(targetLength, padString)
})

Vue.filter('mask', (value, maskValue) => {
  return mask(value, maskValue)
})

Vue.filter('rif', value => {
  const tokens = {
    R: {
      pattern: /([VvEeJjGg])/,
      transform: v => v.toUpperCase()
    },
    '#': { pattern: /\d/, transform: v => v.toUpperCase() }
  }

  return mask(value, 'R-########-#', tokens)
})

Vue.filter('cedula', value => {
  const tokens = {
    C: {
      pattern: /([VvEe])/,
      transform: v => v.toUpperCase()
    },
    '#': { pattern: /\d/, transform: v => v.toUpperCase() }
  }

  return mask(value, 'C-########', tokens)
})
