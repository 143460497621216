<template>
  <v-menu
    :close-on-content-click="false"
    :offset-x="offsetX"
    :offset-y="offsetY"
    :open-on-hover="openOnHover"
    :transition="transition"
    :value="openMenu"
    :bottom="bottom"
    :auto="auto"
    :content-class="subMenu ? 'menu-content submenu' : 'menu-content primary'"
  >
    <template v-slot:activator="{ on }">
      <v-list-item
        v-if="subMenu"
        class="d-flex justify-space-between"
        v-on="on"
      >
        <v-list-item-title>{{ $t(name) }}</v-list-item-title>
        <div class="flex-grow-1"></div>
        <v-icon class="list_color">mdi-chevron-right</v-icon>
      </v-list-item>
      <span
        v-else
        role="button"
        class="menu-item d-flex flex-row align-center"
        v-on="on"
        :color="color"
        :class="itemClass"
        @click="openMenu = true"
      >
        <v-icon v-if="icon" class="mt-1">{{ icon }}</v-icon>
        <span class="margin-idiom"> {{ $t(name) }} </span>
      </span>
    </template>
    <v-list dense nav min-width="8rem">
      <template v-for="(item, index) in items">
        <v-divider v-if="item.isDivider" :key="index" />
        <sub-menu
          v-else-if="item.items"
          :key="index"
          :name="$t(item.title)"
          :items="item.items"
          :open-on-hover="false"
          :offset-x="true"
          :offset-y="false"
          :sub-menu="true"
          :bottom="false"
          :auto="false"
          @click="emitClickEvent"
        />
        <v-list-item
          v-else-if="!item.permission || hasPermission(item.permission)"
          :key="index"
          @click="emitClickEvent(item)"
        >
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>
<script>
import { mapState } from 'vuex'
import { hasPermission } from '@/utils'
export default {
  name: 'SubMenu',
  props: {
    name: {
      type: String,
      required: true
    },
    icon: String,
    items: Array,
    color: {
      type: String,
      default: 'primary'
    },
    offsetX: {
      type: Boolean,
      default: false
    },
    offsetY: {
      type: Boolean,
      default: true
    },
    openOnHover: {
      type: Boolean,
      default: false
    },
    subMenu: {
      type: Boolean,
      default: false
    },
    transition: {
      type: String,
      default: 'v-menu-transition'
    },
    bottom: {
      type: Boolean,
      default: true
    },
    auto: {
      type: Boolean,
      default: true
    },
    itemClass: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    emitClickEvent(item) {
      this.$emit('click', item)
      this.openMenu = false
    },

    hasPermission(permission) {
      return hasPermission(this.user, permission)
    }
  },
  data: () => ({
    openMenu: false
  })
}
</script>
<style scoped>
.list_color {
  color: #686f8d !important;
}

.margin-idiom {
  margin-left: 7px;
}
</style>
