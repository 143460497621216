<template>
  <v-app id="logoscorp">
    <!-- DRAWER -->
    <AppDrawer class="d-print-none" />
    <!-- TOOLBAR -->
    <AppToolBar class="d-print-none" />

    <loading :loading="loading" />

    <!-- ROUTER VIEW -->
    <v-fade-transition mode="out-in">
      <router-view class="container content-box" />
    </v-fade-transition>
    <!-- 
      duraction: 1800 = 30 mins 
      reminders: 60 = 1 min 
    -->
    <v-idle
      @idle="handleIdle"
      @remind="handleRemind"
      :reminders="[60]"
      :wait="1"
      :duration="1800"
      style="display: none;"
    />

    <!-- FOOTER -->
    <AppFooter />
  </v-app>
</template>

<script>
import AppToolBar from '@/components/core/AppToolBar'
import AppFooter from '@/components/core/AppFooter'
import AppDrawer from '@/components/core/AppDrawer'
import Loading from '../components/commons/Loading'
import { mapActions, mapState } from 'vuex'

export default {
  components: { AppToolBar, AppDrawer, AppFooter, Loading },
  computed: {
    ...mapState(['loading'])
  },
  data() {
    return {
      idleDialog: null
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    async handleRemind() {
      if (!this.idleDialog) {
        this.idleDialog = await this.$dialog.warning({
          title: this.$t('inactivity_title'),
          text: this.$t('inactivity_content'),
          waitForResult: false,
          showClose: false,
          icon: null,
          actions: {
            true: {
              text: this.$t('btn_accept'),
              color: 'primary'
            }
          }
        })
      }
    },
    async handleIdle() {
      try {
        if (this.idleDialog) this.idleDialog.close()
        await this.logout()
      } catch (error) {
        console.log(error)
      } finally {
        this.$router.push({ name: 'Login' })
      }
    }
  }
}
</script>
<style>
.theme--light.v-application {
  background: #f0f0f7;
}

.v-breadcrumbs__item,
.v-breadcrumbs__divider,
.v-breadcrumbs__item--disabled {
  color: #757575;
}
</style>
