export function SET_REQUEST(state, request) {
  state.request = request
}

export function SET_CLIENT_REQUEST(state, clientRequest) {
  state.clientRequestData = clientRequest
}

export function SET_TRANSFER_REDIRECT(state, transferRedirect) {
  state.transferRedirect = transferRedirect
}

export function SET_PERMANENT_INSTRUCTION(state, permanentInstruction) {
  state.permanentInstruction = permanentInstruction
}
  
  