import Vue from 'vue'
import dayjs from 'dayjs'
var customParseFormat = require('dayjs/plugin/customParseFormat')
require('dayjs/locale/es')
dayjs.locale('es')
dayjs.extend(customParseFormat)

Object.defineProperties(Vue.prototype, {
  $date: {
    get() {
      return dayjs
    }
  }
})
