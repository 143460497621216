<template>
  <v-navigation-drawer
    :value="drawer"
    @input="setDrawer($event)"
    :dark="dark"
    :width="260"
    right
    app
    class="d-print-none hidden-md-and-up"
    fixed
    temporary
    overlay-color="#00559E"
    touchless
  >
    <v-container fluid class="d-flex justify-end">
      <v-icon color="#00559E" @click="toggleDrawer" class="">mdi-close</v-icon>
      <!-- <img src="../../assets/close.png" alt="Close" class="mb-2" @click="toggleDrawer" /> -->
    </v-container>
    <v-container fluid>
      <img class="margin-logo" height="40" :src="desktopLogo" alt="AV - AGIL" />
    </v-container>

    <v-list :dark="dark" class="margin-list">
      <template v-for="item in items">
        <!--group with subitems-->
        <v-list-group
          v-if="item.items"
          v-show="hasPermission(item.permission)"
          :key="item.title"
          :group="item.group"
          :active-class="dark ? 'list_color' : 'list_color'"
          no-action
        >
          <template #activator>
            <v-list-item-title class="font-weight-bold item-list">
              <v-icon>
                {{ item.icon }}
              </v-icon>
              <span class="ml-2">{{ $t(item.title) }}</span>
            </v-list-item-title>
          </template>

          <template v-for="subItem in item.items">
            <!--sub group-->
            <v-list-group
              v-if="subItem.items"
              v-show="hasPermission(subItem.permission)"
              :key="subItem.name"
              :group="subItem.group"
              sub-group
              class="item-list"
            >
              <template #activator>
                <v-list-item-title class="font-weight-bold">{{
                  $t(subItem.title)
                }}</v-list-item-title>
              </template>

              <v-list-item
                v-for="grand in subItem.items"
                :key="grand.name"
                :to="genChildTarget(item, grand)"
                :href="grand.href"
                v-show="hasPermission(grand.permission)"
                ripple
              >
                <v-list-item-content>
                  <v-list-item-title class="">{{
                    $t(grand.title)
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <!--child item-->
            <v-list-item
              v-else-if="hasPermission(subItem.permission)"
              :key="subItem.name"
              :to="genChildTarget(item, subItem)"
              :href="subItem.href"
              :disabled="subItem.disabled"
              :target="subItem.target"
              :active-class="dark ? 'list-color' : 'black--text'"
              ripple="ripple"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <span class="list_color text--darken-2">{{
                    $t(subItem.title)
                  }}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="subItem.action">
                <v-icon :class="[subItem.actionClass || 'list_color']">{{
                  $t(subItem.action)
                }}</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list-group>
        <v-subheader class="" v-else-if="item.header" :key="item.name">{{
          $t(item.header)
        }}</v-subheader>
        <v-divider v-else-if="item.divider" :key="item.name"></v-divider>
        <!--top-level link-->
        <v-list-item
          v-else
          :to="!item.href ? { name: item.name } : null"
          :href="item.href"
          ripple
          :disabled="item.disabled"
          :target="item.target"
          rel="noopener"
          :key="item.name"
        >
          <v-list-item-action v-if="item.icon" class="d-flex flex-row">
            <v-icon>{{ item.icon }}</v-icon>
            <v-list-item-title class="init ml-2">{{
              $t(item.title)
            }}</v-list-item-title>
          </v-list-item-action>
          <v-list-item-content> </v-list-item-content>
          <v-list-item-action v-if="item.subAction">
            <v-icon class="success--text">{{ item.subAction }}</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
    <language-menu-drawer />
    <v-list class="margin-list">
      <v-list-group
        :active-class="dark ? 'list_color' : 'list_color'"
        no-action
      >
        <template #activator>
          <v-list-item-title class="font-weight-bold item-list">
            <span><v-icon>mdi-account</v-icon></span>
              <span class="ml-2">{{ username }}</span>
          </v-list-item-title>
        </template>
        <v-list-item
          @click="handleReset"
          :active-class="dark ? 'list-color' : 'black--text'"
          ripple="ripple"
        >
          <v-list-item-content>
            <v-list-item-title>
              <span class="list_color text--darken-2">
                {{ twofactor ? $t('twofactor_reset_label') : $t('twofactor_menu_install') }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item class="bottom_list item-list exit">
        <v-btn
          text
          @click="objs[1].click"
          color="#686F8D"
          class="navbutton"
          small
        >
          <span><v-icon>mdi-logout-variant</v-icon></span>
          <span class="ml-3">{{ $t('menu_logout') }}</span>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Menu from '@/api/menu'
import LanguageMenuDrawer from '@/components/commons/LanguageMenuDrawer'
import { hasPermission } from '@/utils'

export default {
  components: { LanguageMenuDrawer },
  name: 'AppDrawer',
  data() {
    return {
      activate: 'primary--text activate-list-item',
      item: 1,
      items: Menu,
      logo: require('@/assets/AV_003_logo_header.svg'),
      dark: false,
      objs: [
        {
          icon: 'mdi-account-box',
          href: '/perfil',
          title: 'Perfíl',
          click: () => {}
        },
        {
          icon: 'mdi-logout',
          title: 'Salir',
          click: () => this.handleLogout()
        }
      ]
    }
  },
  computed: {
    ...mapState(['drawer']),
    ...mapState('auth', ['user']),
    ...mapState('setting', ['whiteBranding']),

    username() {
      return this.user?.first_name.substring(0,30)
    },
    desktopLogo() {
      return this.user?.broker && this.whiteBranding ? `data:image/png;base64,${this.user.broker.img_desktop}` : require('@/assets/AV_003_logo_header.svg')
    },
    twofactor(){
      return this.user?.twofactor_habilitated
    }
  },
  methods: {
    ...mapActions(['setDrawer']),
    ...mapActions('auth', ['logout']),
    ...mapActions(['toggleDrawer']),
    genChildTarget(item, subItem) {
      if (subItem.href) return
      if (subItem.component) {
        return {
          name: subItem.component
        }
      }
      return { name: `${item.group}/${subItem.name}` }
    },
    hasPermission(permission) {
      return hasPermission(this.user, permission)
    },
    async handleLogout() {
      try {
        var brokerCode = this.whiteBranding ? this.user.broker?.code : undefined
        await this.logout()
      } catch (error) {
        console.log(error)
      } finally {
        this.$router.push({ name: 'Login', query: { broker: brokerCode }  })
      }
    },
    async handleReset() {
      this.$router.push({ name: 'Delete' })
    },
  }
}
</script>
<style scoped>
/* .v-list-group.v-list-group--no-action {
  border-bottom: 1px solid #b0b0b0;
} */

.v-list-item__title {
  color: #686f8d;
}

.margin-logo {
  margin-left: 35px;
  padding: 0;
}

.margin-list {
  padding: 0;
  /* padding-left: 32px; */
}

.margin-list a {
  padding-left: 47px;
  font-weight: bold;
}

.item-list {
  /* padding: 0; */
  padding-left: 32px !important;
}

.navbutton {
  text-transform: none !important;
  font-weight: bold;
}

.bottom_list {
  /* padding-left: 32px !important; */
  margin-left: -3.5px;
}
.bottom_list:last-child {
  /* padding-left: 32px !important; */
  margin-left: 1.5px;
}

.list_color {
  color: #686f8d !important;
  font-weight: bold;
}

.theme--light.v-icon {
  color: #686f8d !important;
}
.init {
  margin-left: 2px;
}
</style>
