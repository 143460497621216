<template>
  <sub-menu
    icon="mdi-web"
    :name="idiom.name"
    :items="items"
    @click="handleLocale"
    item-class="mx-auto"
  />
</template>

<script>
import SubMenu from '@/components/commons/SubMenu'
import { mapState, mapActions } from 'vuex'
import { setVeeLocale } from '@/utils'

export default {
  name: 'LanguageMenu',
  components: { SubMenu },
  computed: {
    ...mapState('language', ['languages', 'idiom']),
    items() {
      return this.languages
        .filter(item => item.code !== this.idiom.code)
        .map(item => {
          return {
            ...item,
            title: item.name
          }
        })
    }
  },
  methods: {
    ...mapActions('language', ['setIdiom']),
    ...mapActions(['setLoading']),
    handleLocale(item) {
      this.setLoading(true)
      try {
        this.setIdiom(item)
        setVeeLocale(item.code)
      } catch (error) {
        console.log(error)
      } finally {
        this.setLoading(false)
      }
    }
  },
  created() {
    this.setIdiom(this.idiom)
  }
}
</script>

<style></style>
