import Vue from 'vue'
import Router from 'vue-router'
import routes from './path'
import store from '../store'
import { hasPermission } from '../utils'
import axios from '@/plugins/axios'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

async function getMaintenance() {
  try {
    const { data } = await axios.get(`api/v1/publicSettings`)
    const maintenanceSetting = data.data.find(item => item.key == 'maintenance')
    return maintenanceSetting?.value
  } catch (e) {
    console.log(e);
  }
}

/**
 * Protegemos las rutas para que solo puedan acceder
 * si el usuario esta autentificado, de lo contrario
 * lo redirigimos a la ventana de Login
 */

router.beforeEach(async (to, from, next) => {
  const user = store.state.auth.user
  const auth = to.matched.some(m => m.meta.auth)
  const authFrom = from.matched.some(m => m.meta.auth)
  const permission = to.meta?.permission
  const guest = to.meta?.guest
  const account = store.state.account.account

  const maintenance = await getMaintenance()

  // Maintenance mode
  if (to.name !== 'Maintenance' && maintenance) {
    next({ name: 'Maintenance' })
  } // Authentication
  else if (auth && !user) {
    next({ name: 'Login' })
  } // Not account selected
  else if (auth && !account && to.name === 'Delete') {
    next()
  }
  else if (auth && !account && to.name !== 'AccountSelect') {
    next({ name: 'AccountSelect' })
  } // Guest redirect
  else if (authFrom && guest) {
    if (!account) next({ name: 'AccountSelect' })
    else next({ name: 'Home' })
  } // Route permission
  else if (auth && permission && !hasPermission(user, permission)) {
    next({ name: 'Home' })
  } else next()
})

export default router
