export function SET_SETTINGS(state, settings) {
  state.settings = settings
}

export function SET_MAINTENANCE(state, maintenance) {
  state.maintenance = maintenance
}

export function SET_WHITE_BRANDING(state, whiteBranding) {
  state.whiteBranding = whiteBranding
}