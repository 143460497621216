import Vue from 'vue'
import Vue2Filters from 'vue2-filters'

var Vue2FiltersConfig = {
  currency: {
    symbol: '',
    decimalDigits: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true
  }
}

Vue.use(Vue2Filters, Vue2FiltersConfig)
