<template>
  <v-app-bar
    app
    class="header mb-0 pb-0"
    :height="$vuetify.breakpoint.smAndDown ? 40 : 110"
    elevation="2"
    :color="$vuetify.breakpoint.smAndDown ? 'primary' : 'white'"
  >
    <ticker-tape v-if="$vuetify.breakpoint.mdAndUp" />
    <v-container
      class="d-flex flex-direction-row justify-space-between align-end container"
    >
      <!-- LOGO -->
      <div class="d-flex align-center" :class="$vuetify.breakpoint.mdAndUp ? 'mb-1' : 'mb-2'">
        <router-link to="/">
          <picture >
            <source
              :srcset="desktopLogo"
              media="(min-width: 960px)"
            />
            <img
              :src="mobileLogo"
              :alt="altLogo"
              :width="$vuetify.breakpoint.mdAndUp ? 220 : 110"
              class="logo"
            />
          </picture>
        </router-link>
      </div>
      <!-- MENU HAMBURGER -->
      <v-app-bar-nav-icon
        @click.stop="toggleDrawer"
        class="hidden-md-and-up"
        color="white"
      ></v-app-bar-nav-icon>

      <v-spacer class="hidden-sm-and-down" />

      <app-menu :items="items" @click="handleMenuClick">
        <template #after>
          <!-- Username -->
          <v-menu offset-y content-class="menu-content primary" >
            <template v-slot:activator="{ on, attrs, value }">
              <span
                role="button"
                class="menu-item"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mr-2" v-text="'mdi-account'" />
                <span>{{ firstName }}</span> <span v-show="$vuetify.breakpoint.lgAndUp">{{ lastName }}</span>
                <v-icon class="mr-2" v-if="!value" v-text="'mdi-chevron-down'" />
                <v-icon class="mr-2" v-if="value" v-text="'mdi-chevron-up'" />
              </span>
            </template>
            <v-list>
              <v-list-item @click="handleTwoFactor" class="d-flex justify-space-between">
                <v-list-item-title>
                  {{ twofactor ? $t('twofactor_reset_label') : $t('twofactor_menu_install') }}
                </v-list-item-title>
                <div class="flex-grow-1"></div>
              </v-list-item>
            </v-list>
          </v-menu>

          <!-- Language Menu -->
          <language-menu />

          <!-- Logout -->
          <span role="button" class="menu-item" @click="handleLogout">
            <v-icon class="mr-2" v-text="'mdi-logout-variant'" />{{
              $t('menu_logout')
            }}
          </span>
        </template>
      </app-menu>
    </v-container>
  </v-app-bar>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Menu from '@/api/menu'
import AppMenu from './AppMenu'
import LanguageMenu from '@/components/commons/LanguageMenu'
import TickerTape from '../widgets/TickerTape.vue'

export default {
  components: { AppMenu, LanguageMenu, TickerTape },
  name: 'AppToolBar',
  data() {
    return {
      notifications: [],
      objs: [
        {
          icon: 'mdi-account-box',
          href: '/perfil',
          title: 'Perfíl',
          click: () => {}
        },
        {
          icon: 'mdi-logout',
          title: 'Salir',
          click: async () => {
            try {
              await this.logout()
            } catch (error) {
              console.log(error)
            } finally {
              this.$router.push({ name: 'Login' })
            }
          }
        },
        {
          icon: 'mdi-logout',
          title: 'Usuarios',
          click: async () => {
            try {
              await this.logout()
            } catch (error) {
              console.log(error)
            } finally {
              this.$router.push({ name: 'Login' })
            }
          }
        }
      ],
      item: 1,
      items: Menu
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('setting', ['whiteBranding']),
    firstName() {
      return this.user?.first_name.substring(0,30)
    },
    lastName() {
      return this.user?.last_name.substring(0,30)
    },
    heightNavbar() {
      return this.$vuetify.breakpoint.smAndDown ? 40 : 120
    },

    desktopLogo() {
      return this.user?.broker && this.whiteBranding ? `data:image/png;base64,${this.user.broker.img_desktop}` : require('@/assets/AV_003_logo_header.svg')
    },

    mobileLogo() {
      return this.user?.broker && this.whiteBranding ? `data:image/png;base64,${this.user.broker.img_mobile}` : require('@/assets/logo_mobile.svg')
    },

    altLogo() {
      return this.user?.broker && this.whiteBranding ? `${this.user.broker.name} Logo` : 'AV Agil Logo'
    },
    twofactor(){
      return this.user?.twofactor_habilitated
    }
  },
  methods: {
    ...mapActions(['toggleDrawer']),
    ...mapActions('auth', ['logout']),
    ...mapActions('language', ['setLanguage']),

    async handleLogout() {
      try {
        var brokerCode = this.whiteBranding ? this.user.broker?.code : undefined
        await this.logout()
      } catch (error) {
        console.log(error)
      } finally {
        this.$router.push({ name: 'Login', query: { broker: brokerCode } })
      }
    },

    handleMenuClick(item) {
      this.$router.push({ name: item.component })
    },

    async handleTwoFactor() {
      this.$router.push({ name: 'Delete' })
    },
  },
  created() {
    this.setLanguage('es')
  }
}
</script>
<style lang="sass" scoped>
header.header
  height: 40px

.container
  padding: 0

.v-sheet.elevation-2
  box-shadow: 0px 6px 10px #0000001A !important

.logo
  max-height: 30px

@media only screen and (min-width: 960px)
  .logo
    max-height: 60px

  header.header
    height: 120px

  .theme--light.v-app-bar.v-toolbar.v-sheet
    height: 120px

  .container
    height: 110px
</style>
