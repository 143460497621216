export default [
  {
    title: 'menu_home',
    permission: '',
    name: 'Home',
    component: 'Home',
    icon: 'mdi-home'
  },
  {
    title: 'menu_transfers',
    permission: 'menu-transfers',
    name: 'Transferencias',
    icon: 'mdi-bank-transfer',
    items: [
      {
        name: 'Transferencias',
        title: 'menu_transfers_instructions_permanent',
        permission: 'menu-permanent-instructions',
        component: 'PermanentInstructions'
      },
      {
        name: 'Retiro de Fondos',
        title: 'menu_transfers_retire',
        permission: 'menu-funds-withdrawal-request',
        component: 'Retire'
      },
      {
        name: 'Depósito de Fondos',
        title: 'menu_transfers_deposit',
        permission: 'menu-notification-funds-deposits',
        component: 'Deposit'
      }
    ]
  }
]
